import { useState, useEffect } from 'react'
import { Button } from "./components/ui/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./components/ui/card"
import { AlertCircle, Loader2, Phone, XCircle, AlertTriangle, LogOut, MessageCircle } from 'lucide-react'
import { Alert, AlertDescription, AlertTitle } from "./components/ui/alert"
import { Badge } from "./components/ui/badge"
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "./components/ui/dialog"
import { useNavigate } from 'react-router-dom'
import supabase from './utils/supabase'
import logo from './logo.webp';
import { cancelSubscription, generateSubscriptionLink, getUserProfile, removePhoneNumber } from './services/user-service';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from './components/ui/alert-dialog'

type SubscriptionPlanName = 'FREE' | 'PREMIUM'

interface Subscription {
  id: string | null,
  plan: SubscriptionPlanName,
  created_at: string | null,
  expired_at: string | null
}

interface UserProfile {
  id: string
  phone_number: string | null
  subscription: Subscription
}

export default function Dashboard() {
  const [profile, setProfile] = useState<UserProfile | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [session, setSession] = useState<any | null>(null)
  const navigate = useNavigate();
  const whatsappLink = 'https://wa.me/553131910997?text=Oi';

  useEffect(() => {
    
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (!session) {
        navigate('/login')
      }
      fetchUserProfile(session?.access_token);
      setSession(session)
      console.log(session);
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (!session) {
        navigate('/login')
      }
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [navigate])

  const fetchUserProfile = async (accessToken: string | undefined) => {
    if (!accessToken) return
    setLoading(true)
    setError(null)
    try {
      const profile = await getUserProfile(accessToken);
      setProfile(profile);
    } catch (error) {
      setError('Falha ao carregar perfil do usuário')
      console.error('Error fetching user profile:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleRemovePhone = async () => {
    setLoading(true)
    setError(null)
    try {
      const newProfile = await removePhoneNumber(session.access_token);
      setProfile(newProfile)
    } catch (error) {
      setError('Falha ao remover número de telefone')
      console.error('Error removing phone number:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleUpgradeSubscription = async () => {
    if (!profile) return
    setLoading(true)
    setError(null)
    try {
      const url = await generateSubscriptionLink(session.access_token);
      window.location.replace(url);
      if (error) throw error
    } catch (error) {
      setError('Falha ao atualizar assinatura')
      console.error('Error upgrading subscription:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleCancelSubscription = async () => {
    if (!profile) return
    setLoading(true)
    setError(null)
    try {
      const success = await cancelSubscription(session.access_token);
      if (!success) throw error;
      if (error) throw error
      const profile = await getUserProfile(session.access_token);
      setProfile(profile);
    } catch (error) {
      setError('Falha ao cancelar assinatura')
      console.error('Error canceling subscription:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut()
      if (error) throw error
      navigate('/login') // Redirect to login page after logout
    } catch (error) {
      console.error('Error signing out:', error)
      setError('Falha ao fazer logout')
    }
  }

  const formatExpirationDate = function (expiresAt: string) {
    const expiredAtDate = new Date(expiresAt);
    return expiredAtDate.toLocaleDateString();
  }

  if (loading || !session) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-[#2D1F2F]">
        <Loader2 className="h-8 w-8 animate-spin text-[#80DEEA]" />
      </div>
    )
  }

  if (error || !profile) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-[#2D1F2F] p-4">
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Erro</AlertTitle>
          <AlertDescription>{error || 'Falha ao carregar o dashboard'}</AlertDescription>
        </Alert>
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-[#2D1F2F] text-white ">
      <div className="flex-grow p-4">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center justify-between mb-8">
            <div className="flex items-center">
              <img
                src={logo}
                alt="Palavras Logo"
                width={40}
                height={40}
                className="rounded-lg mr-4"
              />
              <h1 className="text-3xl font-bold">Dashboard do Palavras</h1>
            </div>
            <Button
              variant="link"
              className="border-white text-white hover:bg-white/10"
              onClick={handleLogout}
            >
              <LogOut className="mr-2 h-4 w-4" />
              Sair
            </Button>
        </div>
        
        <Card className="bg-white/10 mb-4">
          <CardHeader>
            <CardTitle className="text-xl text-white">Endereço de email</CardTitle>
            <CardDescription className="text-gray-300">
              Seu endereço de email cadastrado
            </CardDescription>
          </CardHeader>
          <CardContent>
          <ul className="space-y-2">
          <li key={profile.phone_number} className="flex items-center justify-between bg-white/5 p-3 rounded">
          <span className="text-white">{session.user.email}</span>
          </li>
          </ul>
          </CardContent>
        </Card>
        <Card className="bg-white/10 mb-4">
          <CardHeader>
            <CardTitle className="text-xl text-white">Gerenciar números de telefone</CardTitle>
            <CardDescription className="text-gray-300">
              Seus números de telefone verificados
            </CardDescription>
          </CardHeader>
          <CardContent>
            {profile.phone_number ? (
              <ul className="space-y-2">
                <li key={profile.phone_number} className="flex items-center justify-between bg-white/5 p-3 rounded">
                    <span className="text-white">{profile.phone_number}</span>
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        <Button
                        variant="destructive"
                        size="sm"
                        // onClick={() => handleRemovePhone(profile.phone_number)}
                        aria-label={`Remover número ${profile.phone_number}`}
                      >
                          <XCircle className="h-4 w-4" />
                        </Button>
                      </AlertDialogTrigger>
                      <AlertDialogContent className="bg-[#2D1F2F] text-white font-customFont antialiased">
                        <AlertDialogHeader>
                          <AlertDialogTitle>Tem certeza que deseja remover?</AlertDialogTitle>
                          <AlertDialogDescription className='text-white'>
                            Essa ação desvinculará o número de telefone da sua conta e será necessário verificá-lo novamente.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel className='border-white text-white bg-white/0 hover:text-white hover:bg-white/40'>Cancelar</AlertDialogCancel>
                          <AlertDialogAction asChild>
                            <Button className='bg-white hover:bg-gray-200 text-[#2D1F2F]' onClick={() => {handleRemovePhone();}}>Desvincular</Button>
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                    
                  </li>
              </ul>
            ) : (
              <p className="text-center text-gray-400">Nenhum número de telefone verificado</p>
            )}
          </CardContent>
          <CardFooter>
            <Button
              className="w-full bg-white hover:bg-gray-200 text-[#2D1F2F]"
              onClick={() => navigate('/phone-verification')}
            >
              <Phone className="mr-2 h-4 w-4" />
              {profile.phone_number ? 'Substituir número' : 'Adicionar número'}
            </Button>
          </CardFooter>
        </Card>
        <Card className="bg-white/10 mb-4">
          <CardHeader>
            <CardTitle className="text-xl text-white">Gerenciar assinatura</CardTitle>
            <CardDescription className="text-gray-300">
              Seu plano atual e opções de upgrade
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-between bg-white/5 p-4 rounded">
              <div>
                <p className="font-semibold mb-1 text-white ">Plano atual:</p>
                <Badge 
                  variant={profile.subscription.plan === 'PREMIUM' ? 'default' : 'secondary'} 
                  className={`${
                    profile.subscription.plan === 'PREMIUM' 
                      ? 'bg-gradient-to-r from-yellow-300 to-yellow-500 text-black font-semibold border border-yellow-600 shadow-sm' 
                      : 'bg-white text-[#2D1F2F]'
                  }`}
                >
                  {profile.subscription.plan === 'PREMIUM' ? 'Diário Ilimitado' : 'Diário'}
                </Badge>
                <p className="text-sm mt-2 text-white">
                  {profile.subscription.plan === 'PREMIUM' ? 'R$ 4,99/mês' : 'Gratuito'}
                </p>
              </div>
              {profile.subscription.plan === 'FREE' && (
                <Dialog>
                  <DialogTrigger asChild>
                    {/* <Button className="bg-white hover:bg-gray-200 text-[#2D1F2F]">
                      <CreditCard className="mr-2 h-4 w-4" />
                      Upgrade para Premium
                    </Button> */}
                    <Button className="relative bg-[#2D1F2F] text-white border-2 border-yellow-400 hover:bg-[#3D2F3F] overflow-hidden group">
                      <span className="relative z-10">Upgrade para Ilimitado</span>
                      <span className="absolute inset-0 bg-gradient-to-r from-yellow-400 via-yellow-200 to-yellow-400 opacity-50 group-hover:opacity-75 animate-shine"></span>
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="bg-[#2D1F2F] text-white font-customFont antialiased">
                    <DialogHeader className='font-customFont antialiased'>
                      <DialogTitle className='font-customFont antialiased'>Upgrade para o Plano Premium</DialogTitle>
                      <DialogDescription className="text-gray-300">
                        Desfrute de recursos exclusivos e jogabilidade ilimitada
                      </DialogDescription>
                    </DialogHeader>
                    <div className="space-y-4 py-4">
                      <p className="font-semibold">Benefícios do Plano Premium:</p>
                      <ul className="list-disc list-inside space-y-2 text-gray-300">
                        <li>10 jogos por dia</li>
                        <li>Desafios diários exclusivos</li>
                        <li>Dicas e truques avançados</li>
                        <li>Suporte prioritário</li>
                      </ul>
                      <p className="font-semibold">Preço: R$ 4,99/mês</p>
                      <p className="text-sm text-gray-300">Você está atualizando do plano Gratuito (R$ 0,00/mês)</p>
                    </div>
                    <DialogFooter>
                      <Button className="w-full bg-white hover:bg-gray-200 text-[#2D1F2F]" onClick={handleUpgradeSubscription}>
                        Confirmar Upgrade
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              )}
              {(profile.subscription.plan === 'PREMIUM' && !profile.subscription.expired_at) && (
                <div className="space-y-2">
                <Dialog>
                  <DialogTrigger asChild>
                    <Button 
                      variant="outline" 
                      className="w-full border-white text-white bg-white/0 hover:text-white hover:bg-white/40"
                    >
                      Cancelar Plano
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="bg-[#2D1F2F] text-white">
                    <DialogHeader>
                      <DialogTitle className="flex items-center">
                        <AlertTriangle className="h-5 w-5 text-yellow-500 mr-2" />
                        Confirmar Cancelamento
                      </DialogTitle>
                      <DialogDescription className="text-gray-300">
                        Tem certeza que deseja cancelar seu plano Premium?
                      </DialogDescription>
                    </DialogHeader>
                    <div className="space-y-4 py-4">
                      <p className="font-semibold text-red-400">Ao cancelar, você perderá acesso a:</p>
                      <ul className="list-disc list-inside space-y-2 text-gray-300">
                        <li>Todos os jogos por dia (voltará para 1 jogo/dia)</li>
                        <li>Pontue em todos os jogos</li>
                        <li>Suporte prioritário</li>
                      </ul>
                      <Alert variant="destructive" className="bg-red-900 border-red-600">
                        <AlertTriangle className="h-4 w-4" />
                        <AlertTitle>Atenção</AlertTitle>
                        <AlertDescription>
                          Seu acesso Premium continuará até o final do período de faturamento atual.
                        </AlertDescription>
                      </Alert>
                    </div>
                    <DialogFooter className="space-x-2">
                      <DialogClose asChild>
                        <Button variant="ghost" className="w-full bg-gray-700 text-white hover:bg-gray-600">
                          Manter Plano Premium
                        </Button>
                      </DialogClose>
                      <Button 
                        variant="destructive" 
                        className="w-full"
                        onClick={handleCancelSubscription}
                      >
                        Confirmar Cancelamento
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>
              )}
              {(profile.subscription.plan === 'PREMIUM' && profile.subscription.expired_at) && (
                <div className="space-y-2">
                <p className="font-semibold text-red-400">Seu plano termina em {formatExpirationDate(profile.subscription.expired_at)}</p>
              </div>
              )}
            </div>
          </CardContent>
        </Card>
        <Card className="bg-white/10 mb-4">
          <CardHeader>
            <CardTitle className="text-xl text-white">Acessar Jogo</CardTitle>
          </CardHeader>
          <CardFooter>
            <Button
              className="w-full bg-white hover:bg-gray-200 text-[#2D1F2F]"
              asChild
            >
              <a href={whatsappLink}><MessageCircle className="mr-2 h-4 w-4" />
              Jogar agora</a>
              
            </Button>
          </CardFooter>
        </Card>
      </div>
      </div>
      <footer className="bg-[#1F161F] text-white py-8 mt-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <img
                src={logo}
                alt="Palavras Logo"
                width={40}
                height={40}
                className="rounded-lg inline-block mr-2"
              />
              <span className="text-xl font-bold">Palavras</span>
              <div className="mt-4 text-center text-sm text-gray-400">
            © 2024 Palavras. Todos os direitos reservados.
          </div>
            </div>
            <nav>
              <ul className="flex space-x-4">
                <li>Contato <a href="mailto:suporte@palavras.ai" className="hover:underline">suporte@palavras.ai</a></li>
              </ul>
            </nav>
          </div>
          
        </div>
      </footer>
    </div>
  )
}