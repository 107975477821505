import {BASE_API_URL} from '../config'

async function getUserProfile(accessToken: string) {
    const response = await fetch(`${BASE_API_URL}/user/profile`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }
    const profile = await response.json();
    return profile;
}

async function sendVerificationCode(accessToken: string, phoneNumber: string) {
    const encodedPhoneNumber = encodeURIComponent(phoneNumber);
    const response = await fetch(`${BASE_API_URL}/user/verify-phone-number?phone_number=${encodedPhoneNumber}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }
    const profile = await response.json();
    return profile;
}

async function validateCode(accessToken: string, phoneNumber: string, code: string) {
    const encodedPhoneNumber = encodeURIComponent(phoneNumber);
    const encodedCode = encodeURIComponent(code);
    const response = await fetch(`${BASE_API_URL}/user/validate-code?phone_number=${encodedPhoneNumber}&code=${encodedCode}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }
    const profile = await response.json();
    return profile;
}

async function removePhoneNumber(accessToken: string) {
    const response = await fetch(`${BASE_API_URL}/user/phone-number`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }
    const profile = await response.json();
    return profile;
}

async function generateSubscriptionLink(accessToken: string) {
    const response = await fetch(`${BASE_API_URL}/user/subscription-link`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }
    const body = await response.json();
    return body.url;
}


async function cancelSubscription(accessToken: string) {
    const response = await fetch(`${BASE_API_URL}/user/subscription`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
    }
    const body = await response.json();
    return body.success;
}


export { getUserProfile, sendVerificationCode, validateCode, removePhoneNumber, generateSubscriptionLink, cancelSubscription };